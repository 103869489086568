import React from 'react';
import styled from 'styled-components';
import { useDelay } from '../hooks/Delay';
import { Dot, Link } from '../styles';

const Wrapper = styled.div`
  background: ${(props) => props.theme.background};
  border-top: 2px solid ${(props) => props.theme.color.grey};
  padding: 16px;
  position: fixed;
  left: 32px;
  right: 32px;
  bottom: 0;
  display: flex;
  transition: all 400ms ease-in;
  transform: translateY(100%);
  z-index: 10;

  &.show {
    transform: translateY(0);
  }

  & > * {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    position: relative;
    left: auto;
    right: auto;
    bottom: 0;
    text-align: center;
    flex-direction: column;
    padding: 8px 0;
  }
`;

const ContactHeader = styled.span`
  font-size: 1.15rem;
  color: ${(props) => props.theme.text.secondary};
  text-decoration: underline;
  text-underline-offset: 2px;
`;

const ContactLink = styled(Link)`
  font-size: 1.15rem;
`;

const ContactsDesign = styled.div`
  background-image: radial-gradient(${(props) => props.theme.color.grey} 2px, ${(props) => props.theme.background} 2px);
  background-position: 0 0;
  background-size: 16px 16px;
  height: 64px;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;
const Space = styled.div`
  height: 32px;
`;

export const Contact = () => {
  const [reveal] = useDelay(1200);

  return (
    <>
      <ContactsDesign />
      <Wrapper className={reveal ? 'show' : ''}>
        <ContactHeader className='hide-desktop'>Contact</ContactHeader>
        <ContactLink target='_blank' href='mailto:mail.kunalpal@gmail.com'>
          Email
        </ContactLink>
        <Dot className='hide-mobile' />
        <ContactLink target='_blank' href='https://www.linkedin.com/in/iam-kunal-pal/'>
          LinkedIn
        </ContactLink>
        <Dot className='hide-mobile' />
        <ContactLink target='_blank' href='https://github.com/iskunalpal'>
          Github
        </ContactLink>
        <Dot className='hide-mobile' />
        <ContactLink target='_blank' href='https://scholar.google.com/citations?user=8jHtjToAAAAJ&hl=en'>
          Publications
        </ContactLink>
        <Dot className='hide-mobile' />
        <ContactLink target='_blank' href='/kunal_pal_cv.pdf'>
          Résumé
        </ContactLink>
        <Space className='hide-desktop' />
      </Wrapper>
    </>
  );
};
