export interface Experience {
  title: string;
  current: boolean;
  startDate: Date;
  endDate?: Date;
  organization: string;
  orgLink: string;
  location: string;
  details: string[];
}

export const WORK_EXPERIENCE: Experience[] = [
  {
    title: 'Software Development Engineer II',
    current: true,
    startDate: new Date(2020, 0),
    organization: 'Amazon.com',
    orgLink: 'https://www.amazondc.com/',
    location: 'Edinburgh, UK',
    details: [
      'Working on various ML based distributed systems to find correlation within large scale databases, for recruiting activities and e-commerce business',
      'Extensive hand-on experience in building software with AWS, especially in Lambda, EMR, SageMaker, DynamoDB, ECS, S3, CloudFront etc.',
      'Project lead and product owner for several internal and customer facing global services'
    ]
  },
  {
    title: 'Research Engineer',
    current: false,
    startDate: new Date(2018, 10),
    endDate: new Date(2019, 11),
    organization: 'Cybernetics Lab, RWTH Aachen University',
    orgLink: 'https://cybernetics-lab.de',
    location: 'Aachen, Germany',
    details: [
      'Built a fully distributed AI based Dynamic Market Insights (AIDMI) big-data platform in collaboration with one of the largest car manufacturers in Germany',
      'Experience working with recommendation systems, knowledge graphs, localization technologies and data pipelines',
      'Project lead for several projects including an EU funded MRI virtualization app for kids in collaboration with Philips and KU Leuven'
    ]
  },
  {
    title: 'Student Research Assistant',
    current: false,
    startDate: new Date(2017, 0),
    endDate: new Date(2018, 7),
    organization: 'RWTH Aachen University',
    orgLink: 'https://www.ima-zlw-ifu.rwth-aachen.de/en/home.html',
    location: 'Aachen, Germany',
    details: [
      'Worked as student research assistant at numerous RWTH Aachen faculties (Operations Research, Communication Systems, Mechanical Engineering)',
      'Experience working with optimization algorithms, linear solvers, media coding and job scheduling algorithms'
    ]
  },
  {
    title: 'Research Engineer',
    current: false,
    startDate: new Date(2016, 0),
    endDate: new Date(2016, 7),
    organization: 'Solar Energy Research Institute of Singapore (SERIS)',
    orgLink: 'http://www.seris.nus.edu.sg/',
    location: 'Singapore',
    details: [
      'Researched on multi-objective optimization in power systems unit commitment problem',
      'Designed a reliable hybrid PV systems for use in isolated electric grids e.g. island and remote environments',
      'Designed and used distributed computing in faster load forecasting in scalable power systems'
    ]
  },
  {
    title: 'Software Engineer',
    current: false,
    startDate: new Date(2015, 5),
    endDate: new Date(2015, 11),
    organization: 'Applied Research Works Inc.',
    orgLink: 'https://corp.cozeva.com/',
    location: 'Kolkata, India',
    details: [
      'Data mining and pattern recognition for health-care analytics and prediction in the products COZEVA, SUREMETRICS',
      'UX development for analytics tools, primarily using Javascript (NodeJS, React, D3)'
    ]
  }
];

export const INTERNSHIPS: Experience[] = [
  {
    title: 'Dept. of Electrical and Computer Engineering',
    current: false,
    startDate: new Date(2014, 5),
    endDate: new Date(2014, 7),
    organization: 'National University of Singapore (NUS)',
    orgLink: 'http://nus.edu.sg/',
    location: 'Singapore',
    details: [
      'Title: Application of Distributed algorithms in Thermal Generation Scheduling',
      'Advisor: Prof. Dipti Srinivasan'
    ]
  },
  {
    title: 'Dept. of Electrical Engineering',
    current: false,
    startDate: new Date(2013, 11),
    endDate: new Date(2014, 1),
    organization: 'Indian Institute of Technology - Delhi (IIT-D)',
    orgLink: 'http://www.iitd.ac.in/',
    location: 'Delhi, India',
    details: [
      'Title: Biometric Score classification and fusion using pattern recognition',
      'Advisor: Prof. Bijay Ketan Panigrahi'
    ]
  },
  {
    title: 'Electrical and Communication Sciences Unit',
    current: false,
    startDate: new Date(2013, 5),
    endDate: new Date(2013, 7),
    organization: 'Indian Statistical Institute (ISI)',
    orgLink: 'https://www.isical.ac.in/',
    location: 'Kolkata, India',
    details: [
      'Title: Solving Dynamic Constrained Optimization with Evolutionary Computation',
      'Advisor: Prof. Swagatam Das'
    ]
  }
];

export const EDUCATION: Experience[] = [
  {
    title: 'Master of Science',
    current: false,
    startDate: new Date(2016, 8),
    endDate: new Date(2018, 8),
    organization: 'RWTH Aachen University',
    orgLink: 'https://www.rwth-aachen.de/',
    location: 'Aachen, Germany',
    details: [
      'Topic: Communications Engineering',
      'GPA: 1.9/5 (ECTS)',
      'Thesis Topic: Dictionary Learning based Sub-pixel Motion Estimation in Video'
    ]
  },
  {
    title: 'Bachelor of Engineering',
    current: false,
    startDate: new Date(2011, 7),
    endDate: new Date(2015, 4),
    organization: 'Jadavpur University',
    orgLink: 'http://www.jaduniv.edu.in/',
    location: 'Kolkata, India',
    details: [
      'Topic: Electronics and Telecommunications Engineering',
      'GPA: 1.49/5 (ECTS Converted)',
      'Thesis Topic: Application of statistical techniques in retinal Microaneurysm detection'
    ]
  }
];
