// Types
export enum FilterNames {
  WORK_EXPERIENCE = 'Work Experience',
  EDUCATION = 'Education',
  INTERNSHIPS = 'Internships',
  PROJECTS = 'Projects'
}

export interface Filter {
  name: FilterNames;
  selected: boolean;
}

// Defaults
export const DEFAULT_FILTERS: Filter[] = [
  {
    name: FilterNames.WORK_EXPERIENCE,
    selected: true
  },
  {
    name: FilterNames.EDUCATION,
    selected: true
  },
  {
    name: FilterNames.INTERNSHIPS,
    selected: false
  },
  {
    name: FilterNames.PROJECTS,
    selected: false
  }
];
