import React from 'react';
import styled from 'styled-components';
import { FilterNames, SPACE_S } from '../configs';
import { Experience } from '../configs/bullets/experience';
import { useFilters } from '../hooks/context';
import { useDelay } from '../hooks/Delay';
import { BulletPoint } from './BulletPoint';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  font-size: 1rem;
  margin-right: ${SPACE_S};
  color: ${(props) => props.theme.text.secondary};
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background 300ms ease-in-out;

  &.colored {
    color: ${(props) => props.theme.color.blue};
  }

  & > span {
    margin-left: 8px;
  }

  &.colored > span {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &:active,
  &.active {
    background: ${(props) => props.theme.color.darkGrey};
  }
`;

const Line = styled.div`
  flex: 1;
  height: 2px;
  background-color: ${(props) => props.theme.color.grey};
  transform: scaleX(0);
  transition: transform 500ms ease-in;
  transform-origin: left;

  &.show {
    transform: scaleX(1);
  }
`;

const Section = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 500ms ease-in;

  &.show {
    max-height: 2000px;
  }
`;

interface FilteredSectionProps {
  text: string;
  filter: FilterNames;
  experiences: Experience[];
}

export const FilteredSection = ({ text, filter, experiences }: FilteredSectionProps) => {
  const { filters, toggleFilter } = useFilters();
  const [revealSection] = useDelay(700);
  const [revealLine] = useDelay(10);

  const getCurrentFilter = () => filters.find((f) => f.name === filter);

  const toggleExpanded = () => {
    const selectedFilter = getCurrentFilter();
    selectedFilter && toggleFilter(selectedFilter);
  };

  const expanded = getCurrentFilter() && getCurrentFilter()?.selected;
  const isColored = revealSection && expanded ? 'colored' : '';

  return (
    <>
      <Row>
        <Button className={`filter ${isColored}`} onClick={toggleExpanded}>
          {expanded ? '[－]' : '[＋]'}
          <span>{text}</span>
        </Button>
        <Line className={revealLine ? 'show' : ''}></Line>
      </Row>
      <Section className={revealSection && expanded ? 'show' : ''}>
        {experiences.map((exp, index) => (
          <BulletPoint key={index} type={filter} experience={exp} />
        ))}
      </Section>
    </>
  );
};
