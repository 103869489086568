import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Bullets } from '../configs';
import { useFilters } from '../hooks/context';
import { FilteredSection } from './FilteredSection';

interface BulletPointProps {
  bullets: Bullets[];
}

const Wrapper = styled.div`
  padding-bottom: 32px;
`;

export const BulletPoints = ({ bullets }: BulletPointProps) => {
  const { selected, filters } = useFilters();
  const refs = useRef<{ [name: string]: any }>({});

  useEffect(() => {
    if (selected && selected in refs.current) {
      refs.current[selected].scrollIntoView({ behavior: 'smooth' });
      refs.current[selected].querySelector('button.filter').classList.add('active');
      setTimeout(() => refs.current[selected].querySelector('button.filter').classList.remove('active'), 500);
    }
  }, [selected]);

  return (
    <Wrapper>
      {filters.map((filter) => {
        const experiences = bullets.find((b) => b.type === filter.name)?.experiences;
        return (
          <div ref={(element) => (refs.current[filter.name] = element)} key={filter.name}>
            <FilteredSection text={filter.name.toUpperCase()} filter={filter.name} experiences={experiences ?? []} />
          </div>
        );
      })}
    </Wrapper>
  );
};
