import React from 'react';
import styled from 'styled-components';

const RightBar = styled.div`
  background-image: radial-gradient(${(props) => props.theme.color.grey} 2px, ${(props) => props.theme.background} 2px);
  background-position: 0 0;
  background-size: 16px 16px;
  position: fixed;
  right: 48px;
  top: 48px;
  bottom: 16px;
  width: 64px;
  z-index: 20;
`;

export const Background = () => (
  <>
    <RightBar className='hide-mobile' />
  </>
);
