import React, { useState } from 'react';
import styled from 'styled-components';

const CheckBtn = styled.button`
  padding: 4px 8px;
  color: ${(props) => props.theme.text.primary};
  background: ${(props) => props.theme.background};
  border: none;
  border: 1px solid ${(props) => props.theme.color.grey};
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;

  &:hover {
    border: 1px solid ${(props) => props.theme.color.grey};
    background: ${(props) => props.theme.color.lightGrey};
  }

  &:active {
    border: 1px solid ${(props) => props.theme.background};
    background: ${(props) => props.theme.color.darkGrey};
  }

  &.selected {
    color: ${(props) => props.theme.color.blue};
    border: 1px solid ${(props) => props.theme.color.blue};
  }
`;

export interface CheckButtonProps {
  selectable: boolean;
  isSelected?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export const CheckButton = ({ selectable, isSelected, onClick, children }: CheckButtonProps) => {
  const [selected, setSelected] = useState(isSelected);

  const buttonClicked = () => {
    onClick && onClick();
    selectable && setSelected(!selected);
  };
  const className = `${selectable && isSelected ? 'selected' : ''}`;

  return (
    <CheckBtn className={className} onClick={buttonClicked}>
      {children}
    </CheckBtn>
  );
};
