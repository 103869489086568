import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  background: 'white',
  text: {
    primary: '#0b0c0c',
    secondary: '#505f5f'
  },
  color: {
    blue: '#1D70B8',
    lightBlue: '#1D70B8',
    black: '#0b0c0c',
    yellow: '#FFDD00',
    darkGrey: '#505a5f',
    grey: '#b1b4b6',
    lightGrey: '#f3f2f1'
  }
};

export const darkTheme: DefaultTheme = {
  background: '#1b1f23',
  text: {
    primary: '#DDDEE1',
    secondary: '#BDC1c6'
  },
  color: {
    blue: '#35A7FF',
    lightBlue: '#FF8C61',
    black: '#0b0c0c',
    yellow: '#BF7E34',
    darkGrey: '#47494e',
    grey: '#37393e',
    lightGrey: '#27292e'
  }
};
