import { dateLocale, dateOptions } from '../configs';

export const formatDate = (date: Date) => date.toLocaleString(dateLocale, dateOptions);

export const formatDateDiff = (startDate: Date, endDate?: Date) => {
  endDate = endDate ?? new Date(Date.now());
  const offset = new Date(1970, 1);

  const diff = new Date(endDate.getTime() - startDate.getTime() + offset.getTime());
  const years = diff.getFullYear() - 1970;
  const months = diff.getMonth();

  return `
    ${years ? years + ' yr' + (years > 1 ? 's' : '') : ''} 
    ${months ? months + ' mo' + (months > 1 ? 's' : '') : ''}
  `.trim();
};
