import React from 'react';
import styled from 'styled-components';

const SwitchBtn = styled.button`
  background: inherit;
  color: ${(props) => props.theme.color.blue};
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-bottom: 2px solid transparent;
  white-space: nowrap;

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.color.blue};
  }

  &:active {
    background: ${(props) => props.theme.color.darkGrey};
  }
`;

interface SwitchButtonProps {
  checked: boolean;
  textChecked: string;
  textUnchecked: string;
  onClick: () => void;
}

export const SwitchButton = ({ checked, textChecked, textUnchecked, onClick }: SwitchButtonProps) => (
  <SwitchBtn onClick={onClick}>{checked ? textChecked : textUnchecked}</SwitchBtn>
);
