import React, { createContext, useContext, useState } from 'react';
import { DEFAULT_FILTERS, Filter, FilterNames } from '../../configs';

interface FilterContextType {
  filters: Filter[];
  selected?: FilterNames;
  toggleFilter: (filter: Filter) => void;
  resetFilters: () => void;
  selectAllFilters: () => void;
}

// Context
const notImplemented = () => console.error('Filters are not properly configured!');

const FilterContext = createContext<FilterContextType>({
  filters: [],
  toggleFilter: notImplemented,
  resetFilters: notImplemented,
  selectAllFilters: notImplemented
});

export const useFilters = () => {
  return useContext(FilterContext);
};

export const FilterProvider: React.FC<{}> = ({ children }) => {
  const [filters, setFilters] = useState<Filter[]>(DEFAULT_FILTERS);
  const [selected, setSelected] = useState<FilterNames | undefined>();

  const toggleFilter = (filter: Filter) => {
    if (!filter.selected) {
      setSelected(filter.name);
    } else {
      setSelected(undefined);
    }
    const updatedFilters = filters.map((f) => {
      if (f.name !== filter.name) return f;
      return { ...filter, selected: !filter.selected };
    });
    setFilters(updatedFilters);
  };

  const resetFilters = () => {
    setFilters(DEFAULT_FILTERS);
    setSelected(undefined);
  };

  const selectAllFilters = () => {
    setFilters(filters.map((f) => ({ ...f, selected: true })));
    setSelected(undefined);
  };

  return (
    <FilterContext.Provider value={{ filters, toggleFilter, resetFilters, selectAllFilters, selected }}>
      {children}
    </FilterContext.Provider>
  );
};
