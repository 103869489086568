import React from 'react';

import { ThemeProvider } from 'styled-components';
import { BulletPoints, Filters, Title, Contact, Background } from './components';
import { BULLETS } from './configs';
import { FilterProvider, DetailsProvider } from './hooks/context';
import { darkTheme, GlobalStyles, Root } from './styles';

export const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <Root>
        <FilterProvider>
          <DetailsProvider>
            <Title />
            <Filters />
            <BulletPoints bullets={BULLETS} />
            <Contact />
          </DetailsProvider>
        </FilterProvider>
        <Background />
      </Root>
    </ThemeProvider>
  );
};
