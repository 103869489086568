import styled, { createGlobalStyle } from 'styled-components';
import { SPACE_M, SPACE_S } from '../configs';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    font-family: 'Mukta', sans-serif;
  }

  /* Mobile specific */
  @media (max-width: 768px) {
    .hide-mobile {
      display: none !important;
    }
  }

  /* Desktop specific */
  @media (min-width: 769px) {
    .hide-desktop {
      display: none !important;
    }
  }

  body {
    background: ${(props) => props.theme.background}
  }
`;

export const Root = styled.div`
  padding-left: 48px;
  color: ${(props) => props.theme.text.primary};
  font-size: 1rem;
  max-width: 768px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 16px;
  }
`;

export const H2 = styled.h2`
  padding: ${SPACE_M} 0 ${SPACE_S} 0;
  font-size: 2.3em;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.color.blue};
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover {
    text-decoration-thickness: 2px;
  }
`;

export const Dot = styled.span`
  &::before {
    content: '·';
    font-weight: 900;
    padding: 8px 0;
    color: ${(props) => props.theme.color.darkGrey};
  }
`;
