import React from 'react';
import styled from 'styled-components';
import { useDetails, useFilters } from '../hooks/context';
import { Dot } from '../styles';
import { CheckButton } from '../styles/custom/CheckButton';
import { SwitchButton } from '../styles/custom/SwitchButton';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

const IndividualFilters = styled.div`
  & > button {
    margin-right: 8px;
  }
`;

const Divider = styled.div`
  flex: 1;
`;

export const Filters = () => {
  const { filters, toggleFilter, resetFilters, selectAllFilters } = useFilters();
  const { showDetails, toggleDetails } = useDetails();

  const areAllSelected = () => {
    return filters.find((filter) => !filter.selected) === undefined;
  };

  return (
    <Wrapper className='hide-mobile'>
      <IndividualFilters>
        {filters.map((filter, index) => (
          <CheckButton key={index} selectable={true} isSelected={filter.selected} onClick={() => toggleFilter(filter)}>
            {filter.name.toUpperCase()}
          </CheckButton>
        ))}
      </IndividualFilters>
      <Divider />
      <SwitchButton
        checked={areAllSelected()}
        onClick={areAllSelected() ? resetFilters : selectAllFilters}
        textChecked='Reset Selection'
        textUnchecked='Select All'
      />
      <Dot />
      <SwitchButton
        checked={showDetails}
        onClick={toggleDetails}
        textChecked='Collapse All'
        textUnchecked='Expand All'
      />
    </Wrapper>
  );
};
