import { useEffect, useState } from 'react';

export const useDelay = (delay?: number) => {
  const [reveal, setReveal] = useState(false);

  useEffect(() => {
    setTimeout(() => setReveal(true), delay ?? 100);
  }, [delay]);

  return [reveal];
};
