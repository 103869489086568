import { FilterNames } from '..';
import { EDUCATION, Experience, INTERNSHIPS, WORK_EXPERIENCE } from './experience';

export interface Bullets {
  type: FilterNames;
  experiences: Experience[];
}

export const BULLETS: Bullets[] = [
  { type: FilterNames.WORK_EXPERIENCE, experiences: WORK_EXPERIENCE },
  { type: FilterNames.EDUCATION, experiences: EDUCATION },
  { type: FilterNames.INTERNSHIPS, experiences: INTERNSHIPS }
];
