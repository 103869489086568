import React, { createContext, useContext, useState } from 'react';

interface DetailsContextType {
  showDetails: boolean;
  toggleDetails: () => void;
}

const DetailsContext = createContext<DetailsContextType>({
  showDetails: false,
  toggleDetails: () => console.error('Details are not available!')
});

export const useDetails = () => {
  return useContext(DetailsContext);
};

export const DetailsProvider: React.FC<{}> = ({ children }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return <DetailsContext.Provider value={{ showDetails, toggleDetails }}>{children}</DetailsContext.Provider>;
};
