import React from 'react';
import styled from 'styled-components';
import { H2 } from '../styles';

const Wrapper = styled.div`
  margin-top: 32px;

  #logo {
    display: inline;
    color: ${(props) => props.theme.color.blue};
    height: 64px;
    width: 64px;
    transform: translateX(-10px);

    .background,
    .top,
    .left,
    .bottom,
    .right {
      transition: transform 300ms ease-in-out;
    }

    &:hover {
      .background {
        fill: ${(props) => props.theme.color.grey};
      }

      .top {
        transform: translateY(-9px);
      }

      .bottom {
        transform: translateY(9px);
      }

      .left {
        transform: translateX(-8px);
      }

      .right {
        transform: translateX(8px);
      }
    }
  }
`;

const TitleH2 = styled(H2)`
  padding: 0;
  margin-top: -8px;
  margin-bottom: 8px;
  font-weight: normal;
`;

export const Title = () => (
  <Wrapper>
    <a href='/'>
      <svg id='logo' width='128' height='128' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='128' height='128' />
        <rect className='background' x='26' y='27' width='76' height='76' />
        <path
          d='M56.984 76.992V88H51.416V42.56H56.984V70.848L69.08 57.92H76.056L63.704 70.592L76.632 88H69.976L59.992 73.984L56.984 76.992Z'
          fill='currentColor'
        />
        <line className='left' x1='26' y1='16' x2='26' y2='112' stroke='currentColor' strokeWidth='4' />
        <line className='right' x1='102' y1='16' x2='102' y2='112' stroke='currentColor' strokeWidth='4' />
        <line className='bottom' x1='112' y1='101' x2='16' y2='101' stroke='currentColor' strokeWidth='4' />
        <line className='top' x1='112' y1='27' x2='16' y2='27' stroke='currentColor' strokeWidth='4' />
      </svg>
    </a>

    <TitleH2>Kunal Pal</TitleH2>
  </Wrapper>
);
