import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FilterNames } from '../configs';
import { Experience } from '../configs/bullets/experience';
import { useDetails } from '../hooks/context';
import { Dot, Link } from '../styles';
import { SwitchButton } from '../styles/custom/SwitchButton';
import { formatDate, formatDateDiff } from '../utils';

const Wrapper = styled.div`
  margin: 8px 0;
  padding: 16px;
  border: 2px solid transparent;
  background: ${(props) => props.theme.color.lightGrey};

  &:hover {
    border: 2px solid ${(props) => props.theme.color.grey};
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;

  & > * {
    padding-right: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 4px;
  }
`;

const OrgLink = styled(Link)`
  font-size: 1.15rem;
`;

const DetailsRow = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.text.secondary};

  & > :not(:last-child) {
    padding-right: 8px;
  }
`;

const Details = styled.ul`
  margin-top: 4px;
  padding-left: 32px;
  border-left: 2px solid ${(props) => props.theme.color.grey};
  overflow: hidden;
  max-height: 0;
  transition: max-height 200ms ease-in-out;

  &.show {
    max-height: 500px;
  }
`;

interface BulletPointProps {
  type: FilterNames;
  experience: Experience;
}

export const BulletPoint = ({ experience }: BulletPointProps) => {
  const { showDetails } = useDetails();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(showDetails);
  }, [showDetails]);

  return (
    <Wrapper>
      <TitleRow>
        <span>{experience.title}</span>
        <Dot className='hide-mobile' />
        <OrgLink href={experience.orgLink} target='_blank'>
          {experience.organization}
        </OrgLink>
      </TitleRow>
      <DetailsRow>
        <span>
          {formatDate(experience.startDate)} -{' '}
          {!experience.current && experience.endDate ? formatDate(experience.endDate) : 'CURRENT'} (
          {formatDateDiff(experience.startDate, experience.endDate)})
        </span>
        <Dot className='hide-mobile' />
        <br className='hide-desktop' />
        <span>{experience.location}</span>
        <Dot className='hide-mobile' />
        <br className='hide-desktop' />
        <SwitchButton
          checked={expanded}
          onClick={() => setExpanded(!expanded)}
          textChecked='Hide details'
          textUnchecked='Show details'
        />
      </DetailsRow>
      <Details className={expanded ? 'show' : ''}>
        {experience.details.map((d, index) => (
          <li key={index}>{d}</li>
        ))}
      </Details>
    </Wrapper>
  );
};
